import { template as template_f86e982a223c43abab83f73df5251030 } from "@ember/template-compiler";
const FKLabel = template_f86e982a223c43abab83f73df5251030(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
