import { template as template_d58a7f49008e4f3f96a1c6fa0a8b68dc } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_d58a7f49008e4f3f96a1c6fa0a8b68dc(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_d58a7f49008e4f3f96a1c6fa0a8b68dc(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_d58a7f49008e4f3f96a1c6fa0a8b68dc(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
