import { template as template_269074ac80644a218358a035c7813ff4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_269074ac80644a218358a035c7813ff4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
